import { get, post } from 'common/request'

export default {
  // 字典列表
  dicList: args => get('/dict-data/getDictType', args),
  // 字典类型列表
  dicTypeList: args => get('/dict/queryList', args),
  // 删除
  remove: args => get('/dict-data/del', args),
  // 添加
  insertDic: args => post('/dict-data/add', args),
  // 修改
  amend: args => post('/dict-data/update', args),
  // 清除缓存
  clearCache: () => get('/dict/cleanDictReadis'),
  // 清除组织机构缓存数据
  clearOrg: () => get('/dict/cleanDeptReadis'),
  // 删除dic_type表
  dicTypeRemove: args => get('/dict/del', args),
  // dic_type 新增
  add: args => post('/dict/add', args),
  // dic_type 修改
  update: args => post('/dict/update', args)
}
