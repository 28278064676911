<template>
  <div>
    <a-row>
      <a-col span='14'>
        <a-divider orientation="left">数据字典-列表</a-divider>
      </a-col>
      <a-col
        span='10'
        :style="{ textAlign: 'right' }"
      >
        <a-button
          type="primary"
          @click="addOpen"
          :disabled="isOff"
          icon="plus"
        >添加字典</a-button> &nbsp;
        <a-button
          type="primary"
          @click="updateOpen"
          :disabled="isOff"
          icon="edit"
        >修改字典</a-button>&nbsp;
        <a-button
          type="danger"
          @click="removeOpen"
          :disabled="isOff"
          icon="delete"
        >删除字典</a-button>&nbsp;
      </a-col>
    </a-row>
    <a-row :gutter='20'>
      <a-col span='10'>
        <ax-table
          ref="dictType"
          :show-search="true"
          :dataSourceApi="api.dicTypeList"
          :searchForm="searchForm"
          @add="addDicType"
          :sqlParams="sqlParams"
          :searchActions='searchActions'
          :clickable="true"
          :dataSourceParams="dataSourceParams"
          :columns="columns"
          :scroll="{ y: '55vh', x: '80vw' }"
          @action-column-click="actionColumnClick"
          @click="dicTypeClick"
        />
      </a-col>
      <a-col span='14'>
        <ax-table
          ref="dic"
          @click="dicClick"
          :clickable="false"
          :show-search="false"
          :dataSourceApi="api.dicList"
          :autoLoad="false"
          :dataSourceParams="dataSourceParams1"
          :columns="column"
          :scroll="{ y: '55vh', x: '80vw' }"
        ></ax-table>
      </a-col>
    </a-row>

    <a-drawer
      title="字典数据"
      placement="right"
      :visible="visible"
      @close="onClose"
      width="500px"
    >
      <ax-form
        ref="mainReport"
        :formBuilder="formData"
        :initial-values="initialValues"
        :label-width="140"
      />
      <div :style="{ position: 'absolute', left: 0, bottom: 0, width: '100%', borderTop: '1px solid #e9e9e9',
          padding: '10px 16px', background: '#fff', textAlign: 'right' }">
        <a-button
          :style="{marginRight: '8px'}"
          @click="onClose"
        >取消</a-button>
        <a-button
          @click="saveData"
          type="primary"
        >保存</a-button>
      </div>
    </a-drawer>

    <a-drawer
      title="字典类型数据"
      placement="right"
      :visible="dicTypeVisible"
      @close="onCloseDicType"
      width="500px"
    >
      <ax-form
        ref="dicTypeReport"
        :formBuilder="formDictTypeData"
        :initial-values="initialValues"
        :label-width="140"
      />
      <div :style="{ position: 'absolute', left: 0, bottom: 0, width: '100%', borderTop: '1px solid #e9e9e9',
          padding: '10px 16px', background: '#fff', textAlign: 'right' }">
        <a-button
          :style="{marginRight: '8px'}"
          @click="onCloseDicType"
        >取消</a-button>
        <a-button
          @click="saveDicType"
          type="primary"
        >保存</a-button>
      </div>
    </a-drawer>

    <a-modal
      title="提示"
      v-model="modalVisible"
      @ok="removeData"
    >
      <div style="color:#f60;text-align:center;font-size:24px">
        <p>此操作为不可逆操作，是否确认删除？</p>
      </div>
    </a-modal>

    <a-modal
      title="提示"
      v-model="modalDictVisible"
      @ok="removeDictType"
    >
      <div style="color:#f60;text-align:center;font-size:24px">
        <p>此操作为不可逆操作，是否确认删除？</p>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { isEmpty } from '@/common/tools'
import api from './api'
export default {
  name: 'dic',
  data () {
    return {
      api,
      dicQueryParams: {},
      report: {},
      visible: false,
      modalVisible: false,
      // 字典类型删除模态框
      modalDictVisible: false,
      // 字典类型新增模态框
      dicTypeVisible: false,
      isOff: false,
      formName: 'dic',
      currentType: {},
      currentTypeEntity: {},
      currentDic: {},
      initialValues: {
      },
      isType: '',
      columns: this.$ax.tools.initColumn([
        {
          title: '字典名称',
          dataIndex: 'dicKindDesc',
          ellipsis: true,
          width: 100
        },
        {
          title: '字典编码',
          dataIndex: 'dicKind',
          ellipsis: true,
          width: 80
        }
      ], true, {
        width: 70,
        actions: [
          { props: record => { return { text: '编辑', name: 'edit', type: '#3853db', link: true } } },
          { props: record => { return { text: '删除', name: 'delete', type: '#3853db', link: true } } }
        ]
      }),
      column: this.$ax.tools.initColumn([
        {
          title: '名称',
          dataIndex: 'dicDesc',
          ellipsis: true,
          width: 100
        },
        {
          title: '数据值',
          dataIndex: 'dicCode',
          ellipsis: true,
          width: 60
        },
        {
          title: '备注',
          dataIndex: 'remark1',
          ellipsis: true
        }
      ]),
      formData: this.$common.initGridFormData([
        { label: '名称', type: '', model: 'dicDesc', options: {}, col: { span: 24 }, rules: [{ required: true, message: '名称不能为空' }] },
        { label: '数据值', type: '', model: 'dicCode', options: {}, col: { span: 24 }, rules: [{ required: true, message: '数据值不能为空' }] },
        { label: '备注', type: '', model: 'remark1', options: {}, col: { span: 24 } }
      ], { layout: 'horizontal', labelWidth: 90 }),

      formDictTypeData: this.$common.initGridFormData([
        { label: '名称', type: '', model: 'dicKindDesc', options: {}, col: { span: 24 }, rules: [{ required: true, message: '字典名称不能为空' }] },
        { label: '字典编码', type: '', model: 'dicKind', options: {}, col: { span: 24 }, rules: [{ required: true, message: '字典编码不能为空' }] }
      ], { layout: 'horizontal', labelWidth: 90 }),
      dataSourceParams: {
      },
      dataSourceParams1: {
        dicKind: ''
      },
      searchActions: [
        { name: 'add', text: '添加字典类型' },
        { name: 'search', text: '查询' },
        { name: 'reset', text: '重置' }
      ],
      sqlParams: {
        like: ['dicKindDesc']
      },
      searchForm: this.$common.initGridFormData([
        { label: '字典名称', type: '', model: 'dicKindDesc', options: {}, col: { span: 20 } }
      ])
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.dictType.currentRowIndex = 0
      var row = { record: null }
      row.record = this.$refs.dictType.dataSource[0]
      this.dicTypeClick(row)
    })
  },
  methods: {
    // 单击字典类型
    dicTypeClick (row) {
      this.$refs.dictType.currentRowIndex = row.index
      this.$refs.dic.currentRowIndex = 0
      this.currentType = row.record
      this.dataSourceParams1.dicKind = this.currentType.dicKind
      this.$refs.dic.getDataSource({ current: 1 })
      this.$nextTick(() => {
        setTimeout(() => {
          var row = { record: null }
          row.record = this.$refs.dic.dataSource[0]
          this.dicClick(row)
        }, 500)
      })
    },
    // 点击添加按钮
    addOpen () {
      if (isEmpty(this.currentType)) {
        this.$message.warning('请选中需要添加的字典类型')
        return
      }
      this.visible = true
      this.$nextTick(() => {
        this.$refs.mainReport.setFieldsValue({ dicCode: '', dicDesc: '', remark1: '' })
        this.formData.formValues.id = ''
        this.isType = this.currentType.dicCode
      })
    },

    // 保存数据
    saveData () {
      this.$refs.mainReport.form.validateFields(async (errors, values) => {
        if (!errors) {
          if (this.formData.formValues.id) {
            api.amend(this.formData.formValues).then(res => {
              if (res.data) {
                this.$message.success('修改成功')
                this.$refs.dic.getDataSource({ current: 1 })
                this.currentDic.record = null
              } else {
                this.$message.error('修改失败')
              }
            })
          } else {
            var report = this.formData.formValues
            report.dicKind = this.currentType.dicKind
            api.insertDic(report).then(res => {
              if (res.data) {
                this.$message.success('保存成功')
                this.dataSourceParams1.dicKind = this.currentType.dicKind
                this.$refs.dic.getDataSource({ current: 1 })
              } else {
                this.$message.error('保存失败')
              }
            })
          }

          this.visible = false
        } else {
          this.$message.warning('请填写完整表单信息')
        }
      })
    },
    // 单击右侧字典数据
    dicClick (row) {
      this.$refs.dic.currentRowIndex = row.index
      this.currentDic = row
    },
    // 修改按钮
    updateOpen () {
      if (isEmpty(this.currentDic.record)) {
        this.$message.warning('请选择需要修改的字典')
        return
      }
      this.visible = true
      if (this.currentDic.record === undefined) {
        this.$refs.mainReport.setFieldsValue({ dicCode: '', dicDesc: '', remark1: '', remark: '' })
      }

      if (this.currentDic.record.id) {
        this.$nextTick(() => {
          this.$refs.mainReport.setFieldsValue(this.currentDic.record)
          this.formData.formValues.id = this.currentDic.record.id
          this.isType = this.currentDic.record.dictValue
        })
      } else {
        this.$refs.mainReport.setFieldsValue({ dicCode: '', dicDesc: '', remark: '' })
      }
    },
    // 删除按钮
    removeOpen () {
      if (isEmpty(this.currentDic.record)) {
        this.$message.warning('请选中需要删除的字典')
        return
      }
      this.modalVisible = true
    },
    removeData () {
      api.remove({ id: this.currentDic.record.id }).then(res => {
        if (res.success === true) {
          this.$message.success('删除成功')
          this.$refs.dic.getDataSource({ current: 1 })
          this.currentDic.record = null
        } else {
          this.$message.error('删除失败')
        }
      })
      this.modalVisible = false
    },
    onClose () {
      this.visible = false
    },
    // 清除缓存
    clearCache () {
      api.clearCache().then((res) => {
        if (res.status === 200) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 清除组织机构缓存数据
    clearOrg () {
      api.clearOrg().then((res) => {
        if (res.status === 200) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    async reset () {
      this.$refs.dictType.setFieldsValue({ dicKindDesc: '' })
      this.searchForm.dicKindDesc = ''
    },
    // 父节点的相关操作
    actionColumnClick (args) {
      const { btn: { name }, record } = args
      switch (name) {
        case 'edit':
          this.dicTypeVisible = true
          this.currentTypeEntity = record
          this.$nextTick(() => {
            this.$refs.dicTypeReport.setFieldsValue(record)
            this.formDictTypeData.formValues.id = record.id
          })
          break
        case 'delete':
          this.modalDictVisible = true
          this.currentTypeEntity = record
          break
      }
    },
    removeDictType () {
      api.dicTypeRemove({ id: this.currentTypeEntity.id }).then(res => {
        if (res.success === true) {
          this.$message.success('删除成功')
          this.$refs.dictType.getDataSource()
        } else {
          this.$message.error('删除失败')
        }
      })
      this.modalDictVisible = false
    },
    addDicType () {
      this.dicTypeVisible = true
      this.$nextTick(() => {
        this.$refs.dicTypeReport.setFieldsValue({ dicKind: '', dicKindDesc: '' })
        this.formDictTypeData.formValues.id = ''
      })
    },
    onCloseDicType () {
      this.dicTypeVisible = false
    },
    saveDicType () {
      this.$refs.dicTypeReport.form.validateFields(async (errors, values) => {
        if (!errors) {
          var report = this.formDictTypeData.formValues
          report.isShow = 'Y'
          report.sxh = '0'
          if (report.id) {
            api.update(report).then(res => {
              if (res.data) {
                this.$message.success('修改成功')
                this.$refs.dictType.getDataSource()
              } else {
                this.$message.error('修改失败')
              }
            })
          } else {
            api.add(report).then(res => {
              if (res.data) {
                this.currentType = res.data
                this.$message.success('保存成功')
                this.dataSourceParams1.dicKind = this.currentType.dicKind
                this.$refs.dic.getDataSource({ current: 1 })
                this.$refs.dictType.getDataSource()
              } else {
                this.$message.error('保存失败')
              }
            })
          }
          this.dicTypeVisible = false
          this.$refs.dictType.getDataSource()
        } else {
          this.$message.warning('请填写完整表单信息')
        }
      })
    }
  }
}
</script>
<style>
/* .ax-table .current-row {
  background-color: #fff;
} */
</style>
